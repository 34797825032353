var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c(_setup.AppPage,{attrs:{"title":"Billing"},scopedSlots:_vm._u([{key:"subtitle",fn:function(){return [_c('p',{staticClass:"subtitle-1 font-grey p-0 m-0"},[_vm._v("Stay up-to-date on your invoices and payments")])]},proxy:true}])},[(_setup.loading)?_c('Loader',{attrs:{"full-page":true}}):_c('div',{staticStyle:{"width":"85%"}},[(false)?_c('div',{staticClass:"payment-methods"},[_c('h1',{staticClass:"font-size-18 font-weight-600 m-0 mb-4"},[_vm._v("Payment Methods")]),_c('v-card',{staticClass:"px-3 py-2 mb-2",attrs:{"max-width":"400","outlined":""}},[_c('div',{staticClass:"d-flex align-center justify-between"},[_c('h2',{staticClass:"m-0 p-0 font-weight-600 font-size-16"},[_vm._v("Default Payment Method")]),_c('TextButton',{staticClass:"m-0 p-0",on:{"click":function($event){_setup.showEditPayModal = true}}},[_c('span',{staticClass:"line-height-21 text-decoration-underline"},[_vm._v("Edit")])])],1),_c('div',{staticClass:"flex"},[_c('div',{staticClass:"mt-1 mr-2"},[_c(_setup.Icon,{attrs:{"name":"card","size":"32"}})],1),_c('div',[_c('p',{staticClass:"m-0 p-0 font-grey line-height-18 font-size-12"},[_vm._v("Visa ending in 6639")]),_c('p',{staticClass:"m-0 p-0 font-grey line-height-18 font-size-12"},[_vm._v("Expiring 10/25")])])])])],1):_vm._e(),_c('div',{staticClass:"billing-history mt-4"},[_c('h1',{staticClass:"font-size-18 font-weight-600 m-0 mb-4"},[_vm._v("Invoices")]),_c(_setup.Table,{ref:"table",staticClass:"row-clickable mb-5",attrs:{"query":_setup.query,"queryKey":"clientInvoices","headers":_setup.headers,"searchFilters":[
          {
            field: 'search',
            label: 'invoices',
            expanded: true,
          },
        ],"listFilters":[
          {
            field: 'state',
            label: 'Payment Status',
            filters: {
              PROCESSING: 'Processing',
              PAID: 'Paid',
              UNPAID: 'Unpaid',
              PARTIALLY_PAID: 'Partially Paid',
            },
          },
        ]},scopedSlots:_vm._u([{key:`item.invoiceType`,fn:function({ item }){return [_c('div',{},[_c('p',{staticClass:"font-weight-600 mt-2 mb-0"},[_vm._v(_vm._s(item.invoiceTypeHumanized))]),_c('p',{staticClass:"mt-0 mb-2"},[_vm._v(_vm._s(item.missionLifecycle.name))])])]}},{key:`item.mission`,fn:function({ item }){return [_c('span',[_vm._v(" "+_vm._s(item.missionLifecycle)+" ")])]}},{key:`item.state`,fn:function({ item }){return [_c('state-chip',{staticClass:"ml-0",staticStyle:{"margin-left":"0 !important"},attrs:{"state":item.state.replaceAll('_', ' ')}})]}},{key:`item.dueAt`,fn:function({ item }){return [_c('span',[_vm._v(_vm._s(_setup.formatDate(item.dueAt, "Do MMMM YYYY")))])]}},{key:`item.download`,fn:function({}){return [_c('span',[_c(_setup.Icon,{attrs:{"name":"download"}})],1)]}},{key:`item.amount`,fn:function({ item }){return [_c('span',[_vm._v("$"+_vm._s(_vm.moneyFormat(item.amount)))])]}},{key:`item.pay`,fn:function({ item }){return [(_setup.canPay(item.state))?_c(_setup.SecondaryButton,{staticClass:"mr-3 remove-text-transform",staticStyle:{"border-color":"black"},attrs:{"color":"black"}},[_vm._v("Make Payment ")]):_c('div')]}}],null,true)})],1)]),_c(_setup.Modal,{attrs:{"title":_setup.showConfirmPaymentModal ? `You are about to make a payment` : `Edit payment details`,"width":"604px"},on:{"close":function($event){_setup.showEditPayModal = false}},model:{value:(_setup.showEditPayModal),callback:function ($$v) {_setup.showEditPayModal=$$v},expression:"showEditPayModal"}},[(!_setup.showConfirmPaymentModal)?_c(_setup.ConfirmPayment):_c(_setup.EditBillingForm)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }